exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apply-tsx": () => import("./../../../src/pages/apply.tsx" /* webpackChunkName: "component---src-pages-apply-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index-2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-submitted-tsx": () => import("./../../../src/pages/submitted.tsx" /* webpackChunkName: "component---src-pages-submitted-tsx" */),
  "component---src-pages-umar-tsx": () => import("./../../../src/pages/umar.tsx" /* webpackChunkName: "component---src-pages-umar-tsx" */)
}

